import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
// import ru from "./ru.json";
import ua from "./ua.json";

/**
 *
 * @param {keyof typeof en} code
 * @param {object} options
 * @returns {string}
 */
export const t = (code, options) => i18n.t(code, options);

// export const supportedLngs = ["en", "ru", "ua"];
export const supportedLngs = ["en", "ua"];

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    // ru: {
    //   translation: ru,
    // },
    ua: {
      translation: ua,
    },
  },

  fallbackLng: "ua",
  interpolation: {
    escapeValue: true,
  },

  debug: false,
  supportedLngs: ["en", "ua"],
  // supportedLngs: ["en", "ua", "ru"],
  nonExplicitSupportedLngs: true,
});
