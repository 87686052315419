import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import "./i18n";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./styles/index.scss";
import "nprogress/nprogress.css";
const theme = createTheme();
const root = ReactDOM.createRoot(document.getElementById("root"));

const meta = document.createElement("meta");
meta.setAttribute("name", "viewport");
meta.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");

document.head.appendChild(meta);

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);
